import Flex from '@eversports/react-components/primitives/Flex'
import { Icons } from '@eversports/react-components/primitives/Icon'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import BrandHeadingCover from '../../../assets/heading-cover-3.jpg'
import InfoCard from '../../components/InfoCard'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

const Mission: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex flexDirection="column" verticalSpacing="wide">
    <BrandedHeading img={BrandHeadingCover} alternativeColor>
      Mission
    </BrandedHeading>
    <LimitedWidthSection>
      <Text>
        Our goal is to provide our community with social functions to help in the planning of sport sessions. Get the
        most out of your sport experience and achieve your goals with Eversports. We want to achieve this by creating
        efficient software for sports providers and easier access to sports for everyone, by bringing the best of
        technology to the sports community.
      </Text>
      <Text>
        Danish soufflé marzipan toffee chocolate oat cake jelly beans ice cream. Halvah danish topping tiramisu. Dessert
        jelly-o liquorice marshmallow. Jelly-o sesame snaps donut chocolate bar bonbon. Soufflé macaroon jelly beans
        sweet cupcake cookie. Tootsie roll gingerbread sugar plum candy.
      </Text>
      <Text>
        Jelly bear claw tart. Brownie gummi bears cake chocolate dessert. Sugar plum brownie cotton candy oat cake sweet
        apple pie. Donut chocolate cake sweet roll carrot cake chocolate powder. Chocolate bar halvah soufflé jelly-o.
        Macaroon jelly-o topping sweet roll sesame snaps dragée sugar plum cotton candy. Cupcake chocolate bar apple pie
        brownie icing ice cream marzipan cheesecake fruitcake.
      </Text>
      <InfoCard color="primary.dark" icon={Icons.SEARCH}>
        Make the world become an healthier place by living and loving sports in all its variety!
      </InfoCard>
    </LimitedWidthSection>
  </Flex>
)

export default Mission
