import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import ComponentsHeadingCover from '../../../assets/heading-cover-2.jpg'
import Badge from '../../components/Badge'
import { CodeExample } from '../../utils/CustomReactLiveComponents'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

const code = `
<Flex flexDirection="column" alignItems="center" verticalSpacing="wide">
    <Flex horizontalSpacing="dense" alignItems="center">
        <Avatar alt="Arnold Schwarzenegger" src={AvatarImageExample} />
        <Avatar size="big" alt="Arnold Schwarzenegger" src={AvatarImageExample} />
    </Flex>
    <Flex horizontalSpacing="dense" alignItems="center">
        <Avatar>Arnold Schwarzenegger</Avatar>
        <Avatar size="big" fontSize="x-large">
        Arnold Schwarzenegger
        </Avatar>
    </Flex>
  </Flex>
`

const AvatarComponent: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex flexDirection="column" verticalSpacing="wide">
    <BrandedHeading img={ComponentsHeadingCover} alternativeColor />
    <LimitedWidthSection>
      <Flex horizontalSpacing="dense">
        <Heading is="h4">Avatar</Heading>
        <Badge>Draft</Badge>
      </Flex>
      <Text>
        An avatar component represents an object or entity. An image is the preferred format for an avatar, but if an
        image is unavailable, up to two letters can be used instead
      </Text>
      <CodeExample code={code} />
    </LimitedWidthSection>
  </Flex>
)

export default AvatarComponent
