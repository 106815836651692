import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import UnorderedList from '@eversports/react-components/primitives/UnorderedList'
import React from 'react'

import ComponentsHeadingCover from '../../../assets/heading-cover-2.jpg'
import { CodeExample } from '../../utils/CustomReactLiveComponents'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

const code = `
<Flex horizontalSpacing="dense" justifyContent="center">
    <Button variant="primary">
      Primary
    </Button>
    <Button  variant="secondary">
      Secondary
    </Button>
    <Button variant="danger">
      Danger
    </Button>
    <Button variant="primary" disabled>
      Disabled
    </Button>
    <Button variant="secondary">
      With Icon
      <Icon icon={Icons.ADD} size="x-small" color="text.primary" marginLeft="dense" />
    </Button>
</Flex>
`

const ButtonComponent: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex flexDirection="column" verticalSpacing="wide">
    <BrandedHeading img={ComponentsHeadingCover} alternativeColor />
    <LimitedWidthSection>
      <Heading is="h4">Button</Heading>
      <Text>Buttons are clickable items used to perform an action.</Text>
      <Heading is="h3" variant="small">
        Variants
      </Heading>
      <UnorderedList bulletSize="x-small">
        <Text>
          Primary buttons should be used rarely (mostly just once) per page/component since they should define the
          primary action for the user to take.
        </Text>
        <Text>
          Secondary buttons, which are more subtle than the primary ones, should be used for accompanying (and often not
          not that important) actions on pages/components.
        </Text>
        <Text>Danger buttons, which should be used to identify an undesired action</Text>
      </UnorderedList>
      <Heading is="h3" variant="small">
        Other states
      </Heading>
      <Text>
        Buttons can also be disabled to signalize that in the current application state an action is not possible. They
        can also be used in conjunction with icons to provide the user with visual iconography that is typically used to
        invoke an event or action.
      </Text>
      <CodeExample code={code} />
    </LimitedWidthSection>
  </Flex>
)

export default ButtonComponent
