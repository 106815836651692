import { Theme } from '@eversports/react-components/design-tokens/theme'
import Flex from '@eversports/react-components/primitives/Flex'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
  },
}))

const ClickableCardContent: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles()
  return (
    <Flex flexDirection="column" alignItems="flex-start" verticalSpacing="default" className={classes.content}>
      {children}
    </Flex>
  )
}

export default ClickableCardContent
