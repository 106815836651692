import Flex from '@eversports/react-components/primitives/Flex'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import ComponentsHeadingCover from '../../../assets/heading-cover-2.jpg'
import ClickableCard, {
  ClickableCardContent,
  ClickableCardHeading,
  ClickableCardLink,
  ClickableCardText,
} from '../../components/ClickableCard'
import Routes from '../../data/routes'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

const Components: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex verticalSpacing="wide" flexDirection="column" alignItems="flex-start">
    <BrandedHeading img={ComponentsHeadingCover} alternativeColor>
      Components
    </BrandedHeading>
    <LimitedWidthSection>
      <Text>
        Icing brownie lollipop ice cream pie soufflé icing. Oat cake ice cream liquorice macaroon. Carrot cake candy
        cotton candy bear claw carrot cake wafer sugar plum. Tootsie roll cupcake donut chupa chups muffin pastry
        jelly-o oat cake dessert. Danish chocolate bar chocolate tart sesame snaps dessert gingerbread pie muffin.
        Caramels pudding cake pastry macaroon. Jelly gummies cupcake icing tiramisu.
      </Text>
      <Text>
        Ice cream bear claw lollipop gummi bears carrot cake caramels lollipop. Jelly beans gingerbread tiramisu jelly
        candy canes. Sugar plum jelly beans gummi bears donut. Croissant sugar plum chocolate bar apple pie jelly.
      </Text>
      <Text>
        Toffee jujubes candy canes chupa chups sugar plum cookie icing. Gummi bears jelly beans danish icing croissant
        liquorice lemon drops. Muffin icing chocolate cake cookie topping sweet toffee jelly-o chocolate.
      </Text>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <ClickableCard to={Routes.COMPONENTS_AVATAR}>
          <ClickableCardContent>
            <ClickableCardHeading is="h3">Avatar</ClickableCardHeading>
            <ClickableCardText>Avatar is used to represent an object or entitity visually.</ClickableCardText>
            <ClickableCardLink>View Avatar</ClickableCardLink>
          </ClickableCardContent>
        </ClickableCard>
        <ClickableCard to={Routes.COMPONENTS_BUTTON}>
          <ClickableCardContent>
            <ClickableCardHeading is="h3">Button</ClickableCardHeading>
            <ClickableCardText>Button is used to trigger actions based on a user`&apos;`s action.</ClickableCardText>
            <ClickableCardLink>View Button</ClickableCardLink>
          </ClickableCardContent>
        </ClickableCard>
        <ClickableCard to={Routes.COMPONENTS_TEXTFIELD}>
          <ClickableCardContent>
            <ClickableCardHeading is="h3">Textfield</ClickableCardHeading>
            <ClickableCardText>Textfield is used to allow for text input and validation.</ClickableCardText>
            <ClickableCardLink>View Textfield</ClickableCardLink>
          </ClickableCardContent>
        </ClickableCard>
        <ClickableCard to={Routes.COMPONENTS_PROCESSING}>
          <ClickableCardContent>
            <ClickableCardHeading is="h3">Processing</ClickableCardHeading>
            <ClickableCardText>Processing shows that an action is being currently executed.</ClickableCardText>
            <ClickableCardLink>View Processing</ClickableCardLink>
          </ClickableCardContent>
        </ClickableCard>
      </Flex>
    </LimitedWidthSection>
  </Flex>
)

export default Components
