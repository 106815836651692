import { Theme } from '@eversports/react-components/design-tokens/theme'
import Flex from '@eversports/react-components/primitives/Flex'
import Icon, { Icons } from '@eversports/react-components/primitives/Icon'
import Text from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    backgroundColor: theme.palette.info.main,
    padding: theme.spacing(2),
    width: '100%',
    borderRadius: theme.borderRadii.default,
  },
}))

const InfoBanner: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles()

  return (
    <Flex horizontalSpacing="default" className={classes.banner}>
      <Icon icon={Icons.INFO} color="white" />
      <Text is="span" color="white">
        {children}
      </Text>
    </Flex>
  )
}

export default InfoBanner
