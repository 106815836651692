import Heading, { HeadingProps } from '@eversports/react-components/primitives/Heading'
import React from 'react'

type Props = Pick<HeadingProps, 'is'>

const ClickableCardHeading: React.FC<React.PropsWithChildren<Props>> = ({ is, children }) => (
  <Heading is={is}>{children}</Heading>
)

export default ClickableCardHeading
