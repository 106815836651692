import hydrateReactClient from '@eversports/react-app-base/hydrate-react-client'

import App from './App'
import getLocalizationProvider from './LocalizationProvider'

hydrateReactClient({
  App,
  getLocalizationProvider,
  apolloOptions: { uri: '' },
}).catch((error) => console.error(error))
