import Flex from '@eversports/react-components/primitives/Flex'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import DesignHeadingCover from '../../../assets/heading-cover-1.jpg'
import ClickableCard, {
  ClickableCardContent,
  ClickableCardHeading,
  ClickableCardLink,
  ClickableCardText,
} from '../../components/ClickableCard'
import Routes from '../../data/routes'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

const Design: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex verticalSpacing="wide" flexDirection="column" alignItems="flex-start">
    <BrandedHeading img={DesignHeadingCover} alternativeColor>
      Design
    </BrandedHeading>
    <LimitedWidthSection>
      <Text>
        Icing brownie lollipop ice cream pie soufflé icing. Oat cake ice cream liquorice macaroon. Carrot cake candy
        cotton candy bear claw carrot cake wafer sugar plum. Tootsie roll cupcake donut chupa chups muffin pastry
        jelly-o oat cake dessert. Danish chocolate bar chocolate tart sesame snaps dessert gingerbread pie muffin.
        Caramels pudding cake pastry macaroon. Jelly gummies cupcake icing tiramisu.
      </Text>
      <Text>
        Ice cream bear claw lollipop gummi bears carrot cake caramels lollipop. Jelly beans gingerbread tiramisu jelly
        candy canes. Sugar plum jelly beans gummi bears donut. Croissant sugar plum chocolate bar apple pie jelly.
      </Text>
      <Text>
        Toffee jujubes candy canes chupa chups sugar plum cookie icing. Gummi bears jelly beans danish icing croissant
        liquorice lemon drops. Muffin icing chocolate cake cookie topping sweet toffee jelly-o chocolate.
      </Text>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <ClickableCard to={Routes.DESIGN_LOGO}>
          <ClickableCardContent>
            <ClickableCardHeading is="h3">Logo</ClickableCardHeading>
            <ClickableCardText>Discover the different logos versions and how to use them.</ClickableCardText>
            <ClickableCardLink>Read about Logo</ClickableCardLink>
          </ClickableCardContent>
        </ClickableCard>
        <ClickableCard to={Routes.DESIGN_COLORS}>
          <ClickableCardContent>
            <ClickableCardHeading is="h3">Colors</ClickableCardHeading>
            <ClickableCardText>Discover the colors that we use to give our applications life.</ClickableCardText>
            <ClickableCardLink>Read about Colors</ClickableCardLink>
          </ClickableCardContent>
        </ClickableCard>
        <ClickableCard to={Routes.DESIGN_TYPOGRAPHY}>
          <ClickableCardContent>
            <ClickableCardHeading is="h3">Typography</ClickableCardHeading>
            <ClickableCardText>
              Discover the different font families, sizes and types we use and how to combine them.
            </ClickableCardText>
            <ClickableCardLink>Read about Typography</ClickableCardLink>
          </ClickableCardContent>
        </ClickableCard>
        <ClickableCard to={Routes.DESIGN_ICONS}>
          <ClickableCardContent>
            <ClickableCardHeading is="h3">Icons</ClickableCardHeading>
            <ClickableCardText>Discover the range of icons we use in our applications.</ClickableCardText>
            <ClickableCardLink>Read about Icons</ClickableCardLink>
          </ClickableCardContent>
        </ClickableCard>
      </Flex>
    </LimitedWidthSection>
  </Flex>
)

export default Design
