enum Routes {
  BRAND = '/brand',
  DESIGN = '/design',
  COMPONENTS = '/components',
  BRAND_OUR_JOURNEY = '/brand/our-journey',
  BRAND_VALUES = '/brand/values',
  BRAND_VISION = '/brand/vision',
  BRAND_MISSION = '/brand/mission',
  DESIGN_LOGO = '/design/logo',
  DESIGN_ASSETS = '/design/assets',
  DESIGN_COLORS = '/design/colors',
  DESIGN_TYPOGRAPHY = '/design/typography',
  DESIGN_ICONS = '/design/icons',
  DESIGN_SPACING = '/design/spacing',
  DESIGN_LAYOUT = '/design/layout',
  DESIGN_B2B = '/design/b2b',
  DESIGN_B2C = '/design/b2c',
  DESIGN_TEMPLATES = '/design/templates',
  COMPONENTS_BUTTON = '/components/button',
  COMPONENTS_TEXTFIELD = '/components/textfield',
  COMPONENTS_AVATAR = '/components/avatar',
  COMPONENTS_PROCESSING = '/components/processing',
}

export default Routes
