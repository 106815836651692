import { Theme } from '@eversports/react-components/design-tokens/theme'
import Flex from '@eversports/react-components/primitives/Flex'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import Routes from '../data/routes'

import { Brand, Mission, OurJourney, Values, Vision } from './brand'
import { AvatarComponent, ButtonComponent, Components, ProcessingComponent, TextFieldComponent } from './components'
import { Colors, Design, Icons, Logo, Spacing, Typography } from './design'

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    height: '100%',
  },
  content: {
    paddingBottom: theme.spacing(9),
  },
}))

const Content: React.FC<React.PropsWithChildren<unknown>> = () => {
  const classes = useStyles()

  return (
    <Flex className={classes.section} flexDirection="column" verticalSpacing="default">
      <div className={classes.content}>
        <Switch>
          <Route path="/" exact render={() => <Redirect push to={Routes.BRAND} />} />
          <Route path={Routes.BRAND} exact render={() => <Brand />} />
          <Route path={Routes.BRAND_OUR_JOURNEY} render={() => <OurJourney />} />
          <Route path={Routes.BRAND_VALUES} render={() => <Values />} />
          <Route path={Routes.BRAND_MISSION} render={() => <Mission />} />
          <Route path={Routes.BRAND_VISION} render={() => <Vision />} />
          <Route path={Routes.DESIGN} exact render={() => <Design />} />
          <Route path={Routes.DESIGN_LOGO} exact render={() => <Logo />} />
          <Route path={Routes.DESIGN_COLORS} exact render={() => <Colors />} />
          <Route path={Routes.DESIGN_ICONS} exact render={() => <Icons />} />
          <Route path={Routes.DESIGN_TYPOGRAPHY} exact render={() => <Typography />} />
          <Route path={Routes.DESIGN_SPACING} exact render={() => <Spacing />} />
          <Route path={Routes.COMPONENTS} exact render={() => <Components />} />
          <Route path={Routes.COMPONENTS_BUTTON} exact render={() => <ButtonComponent />} />
          <Route path={Routes.COMPONENTS_TEXTFIELD} exact render={() => <TextFieldComponent />} />
          <Route path={Routes.COMPONENTS_AVATAR} exact render={() => <AvatarComponent />} />
          <Route path={Routes.COMPONENTS_PROCESSING} exact render={() => <ProcessingComponent />} />
        </Switch>
      </div>
    </Flex>
  )
}

export default Content
