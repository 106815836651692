import Text, { TextProps } from '@eversports/react-components/primitives/Text'
import React from 'react'

type Props = Pick<TextProps, 'is'>

const ClickableCardText: React.FC<React.PropsWithChildren<Props>> = ({ is, children }) => (
  <Text is={is}>{children}</Text>
)

export default ClickableCardText
