import { Theme } from '@eversports/react-components/design-tokens/theme'
import Flex from '@eversports/react-components/primitives/Flex'
import Icon, { Icons as allIcons } from '@eversports/react-components/primitives/Icon'
import Text from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import React from 'react'

export type AllIcons = keyof typeof allIcons

interface Props {
  icon: AllIcons
}

const useStyles = makeStyles(({ space }: Theme) => ({
  root: {
    width: space['128px'],
  },
}))

const IconTile: React.FC<React.PropsWithChildren<Props>> = ({ icon }) => {
  const classes = useStyles()

  return (
    <Flex className={classes.root} justifyContent="center" alignItems="center">
      <Flex flexDirection="column" alignItems="center">
        <Icon size="x-large" icon={allIcons[icon]} />
        <Text size="small" color="subtleText">
          {icon}
        </Text>
      </Flex>
    </Flex>
  )
}

export default IconTile
