import { Theme } from '@eversports/react-components/design-tokens/theme'
import Link from '@eversports/react-components/primitives/Link'
import Text from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { RouteMapping } from './SidebarSection'

interface Props {
  route: RouteMapping
}

interface MakeStyleProps {
  selected: boolean
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  heading: ({ selected, disabled }: MakeStyleProps) => ({
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    color: !disabled ? (selected ? theme.palette.white : theme.palette.grey[400]) : theme.palette.grey[700],
    borderLeft: `5px solid ${selected ? theme.palette.primary.main : theme.palette.transparent}`,
    backgroundColor: selected ? theme.palette.grey[600] : theme.palette.text.primary,
    fontWeight: selected ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
    '&:hover': {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.white,
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
  section: ({ disabled }: MakeStyleProps) => ({
    width: '100%',
    textAlign: 'center',
    pointerEvents: disabled ? 'none' : 'auto',
  }),
}))

const SidebarSectionElement: React.FC<React.PropsWithChildren<Props & RouteComponentProps>> = ({ route, location }) => {
  const selected = route.disabled ? false : location.pathname === route.to
  const classes = useStyles({ selected, disabled: route.disabled })

  return (
    <section className={classes.section}>
      <Link to={route.to}>
        <Text className={classes.heading}>{route.text}</Text>
      </Link>
    </section>
  )
}

export default withRouter(SidebarSectionElement)
