import MuiCard, { CardProps as MuiCardProps } from '@mui/material/Card'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as React from 'react'

import { LayoutSpacing, Theme } from '../../design-tokens/theme'

interface CardProps extends MuiCardProps {
  padding?: LayoutSpacing
}

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: Pick<CardProps, 'padding'>) => ({
    width: '100%',
    padding: props.padding ? theme.layoutSpacing[props.padding] : undefined,
  }),
  raised: {
    boxShadow: theme.customShadows.boxShadowDown,
  },
}))

// The default element type of MUI's Card is 'div' and the change of this prop is not allowed
type CardRef = HTMLElementTagNameMap['div']

// eslint-disable-next-line no-shadow
export const Card = React.forwardRef<CardRef, CardProps>(function Card({ raised, padding, className, ...rest }, ref) {
  const classes = useStyles({ padding })
  return <MuiCard {...rest} ref={ref} className={classNames(classes.root, { [classes.raised]: raised }, className)} />
})

export default Card
