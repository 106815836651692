import React from 'react'
import { Helmet } from 'react-helmet-async'

import Layout from './Layout'

const App: React.FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <Helmet>
      <title>Eversports - Design System</title>
    </Helmet>
    <Layout />
  </>
)

export default App
