import { Theme } from '@eversports/react-components/design-tokens/theme'
import Flex from '@eversports/react-components/primitives/Flex/Flex'
import Text from '@eversports/react-components/primitives/Text'
import { useTheme } from '@mui/styles'
import React from 'react'

import DesignHeadingCover from '../../../assets/heading-cover-1.jpg'
import InfoBanner from '../../components/InfoBanner'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

import ColorTile, { ColorItem } from './views/ColorTile'

function createColors(theme: Theme): Array<ColorItem> {
  return [
    {
      name: 'Primary Color',
      color: theme.palette.primary.main,
      alternativeColor: true,
    },
    {
      name: 'Secondary Color',
      color: theme.palette.secondary.main,
      alternativeColor: true,
    },
    {
      name: 'Text Color',
      color: theme.palette.text.primary,
      alternativeColor: true,
    },
    {
      name: 'Subtle Text Color',
      color: theme.palette.subtleText,
      alternativeColor: true,
    },
    {
      name: 'Danger Color',
      color: theme.palette.danger,
      alternativeColor: true,
    },
    {
      name: 'Info Color',
      color: theme.palette.info.main,
      alternativeColor: true,
    },
    {
      name: 'Warning Color',
      color: theme.palette.warning.main,
      alternativeColor: true,
    },
    {
      name: 'Disabled Text Color',
      color: theme.palette.text.disabled,
      alternativeColor: true,
    },
    {
      name: 'Alternative Text Color',
      color: theme.palette.white,
    },
  ]
}

/*
// Use this as soon as there is time
const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    width: '100%',
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))
*/

const Colors: React.FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme()
  // const classes = useStyles()
  const colors = createColors(theme as Theme)

  return (
    <Flex flexDirection="column" verticalSpacing="default" alignItems="flex-start" fullWidth>
      <BrandedHeading img={DesignHeadingCover} alternativeColor>
        Colors
      </BrandedHeading>
      <LimitedWidthSection>
        <Text>Here you can find an overview of our colors.</Text>
        <InfoBanner>By clicking on the color box, you copy the color`&apos;`s hex value</InfoBanner>
        <Flex flexWrap="wrap">
          {colors.map((color) => (
            <ColorTile key={color.name} {...color} />
          ))}
        </Flex>
      </LimitedWidthSection>
    </Flex>
  )
}

export default Colors
