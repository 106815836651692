import { Theme } from '@eversports/react-components/design-tokens/theme'
import Flex from '@eversports/react-components/primitives/Flex'
import Heading, { HeadingVariant } from '@eversports/react-components/primitives/Heading'
import Text, { TextSize } from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import React from 'react'

import DesignHeadingCover from '../../../assets/heading-cover-1.jpg'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

interface StyleType {
  [key: string]: string | number
}

/**
 * Convert style value into humanly readable format
 */
function mapStyleToReadableFormat(attribute: string, value: string | number): string {
  // If value is `rem` value also show converted pixel value
  if (typeof value === 'string' && value.endsWith('rem')) {
    value = `${Number(value.toString().split('rem')[0]) * 16}px (${value})`
  }
  return `${attribute}: ${value}`
}

/**
 * Convert object of styles to a concated list of humanly readable styles
 */
function mapStylesToReadableFormat(styles: StyleType): string {
  return Object.entries(styles)
    .map(([attribute, value]) => mapStyleToReadableFormat(attribute, value))
    .join(', ')
}

/**
 * Map collection of styles into humanly readable styles
 */
function mapStyleCollectionToReadableFormat<T extends { [key: string]: StyleType }, K extends keyof T>(
  collection: T,
): { [key in K]: string } {
  return Object.assign(
    {},
    ...Object.entries(collection).map(([variant, styles]) => ({
      [variant]: mapStylesToReadableFormat(styles),
    })),
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  sampleBackground: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey.A100,
    borderRadius: theme.borderRadii.default,
  },
}))

const Typography: React.FC<React.PropsWithChildren<unknown>> = () => {
  const classes = useStyles()
  const mappedHeadingVariant = mapStyleCollectionToReadableFormat(HeadingVariant)
  const mappedTextSize = mapStyleCollectionToReadableFormat(TextSize)

  return (
    <Flex flexDirection="column" alignItems="flex-start" verticalSpacing="default">
      <BrandedHeading img={DesignHeadingCover} alternativeColor>
        Typography
      </BrandedHeading>
      <LimitedWidthSection>
        <Heading is="h2">Typeface</Heading>
        <Text>
          Our default font for the B2C applications is{' '}
          <Text is="span" fontWeight="bold">
            Montserrat
          </Text>
          , while we use{' '}
          <Text is="span" fontWeight="bold">
            Open Sans
          </Text>{' '}
          for the B2B applications. If the named font is not available you should fall back to the OS default font on
          web and mobile.
        </Text>
        <Heading is="h2">Type Style</Heading>
        <Heading is="h3" variant="small">
          Headings
        </Heading>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          verticalSpacing="default"
          className={classes.sampleBackground}
        >
          {Object.entries(mappedHeadingVariant)
            .reverse()
            .map(([variant, value]) => (
              <>
                <Heading is="h6" variant={variant as keyof typeof HeadingVariant}>
                  {variant} heading
                </Heading>
                <Text size="small" color="subtleText">
                  {value}
                </Text>
              </>
            ))}
        </Flex>

        <Heading is="h3" variant="small">
          Paragraphs
        </Heading>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          verticalSpacing="default"
          className={classes.sampleBackground}
        >
          {Object.entries(mappedTextSize)
            .reverse()
            .map(([size, value]) => (
              <>
                <Text size={size as keyof typeof TextSize}>{size} text</Text>
                <Text size="small" color="subtleText">
                  {value}
                </Text>
              </>
            ))}
        </Flex>
      </LimitedWidthSection>
    </Flex>
  )
}

export default Typography
