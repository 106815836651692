import { Theme } from '@eversports/react-components/design-tokens/theme'
import Flex from '@eversports/react-components/primitives/Flex'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    padding: `${theme.spacing(4)} ${theme.spacing(9)} ${theme.spacing(9)} ${theme.spacing(9)}`,
    maxWidth: 900,
  },
}))

const LimitedWidthSection: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles()

  return (
    <Flex verticalSpacing="wide" flexDirection="column" className={classes.text}>
      {children}
    </Flex>
  )
}

export default LimitedWidthSection
