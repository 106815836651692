import Flex from '@eversports/react-components/primitives/Flex'
import Logo from '@eversports/react-components/primitives/Logo'
import Text from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import React from 'react'

import BrandHeadingCover from '../../../assets/heading-cover-3.jpg'
import OldEversportsLogo from '../../../assets/old-eversports-logo.png'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
  },
  oldLogo: {
    height: 150,
  },
})

const OurJourney: React.FC<React.PropsWithChildren<unknown>> = () => {
  const classes = useStyles()

  return (
    <Flex flexDirection="column" verticalSpacing="wide" className={classes.container}>
      <BrandedHeading img={BrandHeadingCover} alternativeColor>
        Our Journey
      </BrandedHeading>
      <LimitedWidthSection>
        <Flex alignItems="center">
          <Flex flexDirection="column" verticalSpacing="wide">
            <Text>
              Exactly 6 years ago, we started with the vision `&quot;`to give people easier access to sports and to show
              them the various sports opportunities in their surroundings`&quot;`. In the meantime, {'>'} 6 million
              sports activities in
              {'>'} 150 different sports at {'>'} 2500 partner sports facilities/studios in 7 countries have been booked
              online on Eversports.
            </Text>
            <Text>
              The first part of this journey was very exciting and we are very grateful for everything we achieved
              together. together. But we are far from finished ... After all, sport as well as business is:
              `&quot;`evermoving`&quot;`. Consistency is the most important factor for success in every training
              session. As soon as you skip several units, the positive effects effects of the training begin to
              diminish. After 6 years of consistent training, our muscles are stronger, our focus clearer and our vision
              bigger.
            </Text>
            <Text>
              After more than four years we are very happy to present to you our new logo. We are convinced that it
              better expresses our greater vision and our new motto `&quot;`ever moving`&quot;`. We stayed true to our
              roots and so you can see the `&quot;`swush`&quot;` elements of the old logo, a small `&quot;`e`&quot;`, as
              well as a ball. Due to the new round shape the logo looks as if it is in motion and you can recognize a
              rolled mat or a Yin Yang symbol. We have now found a logo with which we can appeal to both our ball sports
              and our studio partners.
            </Text>
            <Flex justifyContent="center" horizontalSpacing="wide">
              <img src={OldEversportsLogo} className={classes.oldLogo} />
              <Logo type="icon" height="128px" />
            </Flex>
          </Flex>
        </Flex>
      </LimitedWidthSection>
    </Flex>
  )
}

export default OurJourney
