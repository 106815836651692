import { Theme } from '@eversports/react-components/design-tokens/theme'
import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import { makeStyles } from '@mui/styles'
import React from 'react'

interface Props {
  img: string
  alternativeColor?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
  },
  image: {
    width: '100%',
    filter: 'brightness(0.7)',
  },
  heading: {
    position: 'absolute',
    padding: `${theme.spacing(2)} ${theme.spacing(9)}`,
  },
}))

const BrandedHeading: React.FC<React.PropsWithChildren<Props>> = ({ img, alternativeColor, children }) => {
  const classes = useStyles()

  return (
    <Flex alignItems="flex-end" className={classes.container} fullWidth>
      <img src={img} className={classes.image} />
      <Heading is="h1" variant="x-large" className={classes.heading} alternativeColor={alternativeColor}>
        {children}
      </Heading>
    </Flex>
  )
}

BrandedHeading.defaultProps = {
  alternativeColor: false,
}

export default BrandedHeading
