import React from 'react'

import Routes from '../../data/routes'
import SidebarSection from '../SidebarSection'

const routesMapping = [
  { text: 'Logo', to: Routes.DESIGN_LOGO },
  { text: 'Assets', to: Routes.DESIGN_ASSETS, disabled: true },
  { text: 'Colors', to: Routes.DESIGN_COLORS },
  { text: 'Typography', to: Routes.DESIGN_TYPOGRAPHY },
  { text: 'Icons', to: Routes.DESIGN_ICONS },
  { text: 'Spacing', to: Routes.DESIGN_SPACING, disabled: true },
  { text: 'Layout', to: Routes.DESIGN_LAYOUT, disabled: true },
  { text: 'B2B', to: Routes.DESIGN_B2B, disabled: true },
  { text: 'B2C', to: Routes.DESIGN_B2C, disabled: true },
  { text: 'Templates', to: Routes.DESIGN_TEMPLATES, disabled: true },
]

const DesignSidebarSection: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SidebarSection routesMapping={routesMapping} />
)

export default DesignSidebarSection
