import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import ComponentsHeadingCover from '../../../assets/heading-cover-2.jpg'
import { CodeExample } from '../../utils/CustomReactLiveComponents'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

const code = `
<PyroForm initialValues={{ name: '' , password: ''}}>
  <Flex horizontalSpacing="wide" justifyContent="center">
    <TextField name="name" label="Name" placeholder="Sport" />
    <TextField name="password" label="Password" type="password" />
  </Flex>
</PyroForm>
`

const TextFieldComponent: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex flexDirection="column" verticalSpacing="wide">
    <BrandedHeading img={ComponentsHeadingCover} alternativeColor />
    <LimitedWidthSection>
      <Heading is="h4">TextField</Heading>
      <Text>
        Textfields inputs are used for a small amount of data entry. They need to be used inside of a Pyroform.
      </Text>
      <CodeExample code={code} />
    </LimitedWidthSection>
  </Flex>
)

export default TextFieldComponent
