import React from 'react'
import { Route, Switch } from 'react-router'

import Routes from '../data/routes'

import { BrandSidebarSection } from './brand'
import { ComponentsSidebarSection } from './components'
import { DesignSidebarSection } from './design'

const Sidebar: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Switch>
    <Route path={Routes.BRAND} render={() => <BrandSidebarSection />} />
    <Route path={Routes.DESIGN} render={() => <DesignSidebarSection />} />
    <Route path={Routes.COMPONENTS} render={() => <ComponentsSidebarSection />} />
  </Switch>
)

export default Sidebar
