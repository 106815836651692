import { Theme } from '@eversports/react-components/design-tokens/theme'
import BaseLink from '@eversports/react-components/primitives/BaseLink'
import Card from '@eversports/react-components/primitives/Card'
import ButtonBase from '@mui/material/ButtonBase'
import { makeStyles } from '@mui/styles'
import React from 'react'

export interface Props {
  to: string
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderTop: `5px solid ${theme.palette.primary.main}`,
    width: 350,
    height: 200,
    textAlign: 'left',
    borderRadius: 0,
    margin: `${theme.spacing(1)} 0`,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  fullWidth: {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
}))

const ClickableCard: React.FC<React.PropsWithChildren<Props>> = ({ to, children }) => {
  const classes = useStyles()
  return (
    <ButtonBase className={classes.fullWidth}>
      <BaseLink to={to} className={classes.fullWidth}>
        <Card className={classes.card}>{children}</Card>
      </BaseLink>
    </ButtonBase>
  )
}

export default ClickableCard
