import { Theme } from '@eversports/react-components/design-tokens/theme'
import BaseLink from '@eversports/react-components/primitives/BaseLink'
import Flex from '@eversports/react-components/primitives/Flex'
import Logo from '@eversports/react-components/primitives/Logo'
import { makeStyles } from '@mui/styles'
import React from 'react'

import BrandIcon from '../../assets/brand-icon.svg'
import ComponentsIcon from '../../assets/components-icon.svg'
import DesignIcon from '../../assets/design-icon.svg'
import Routes from '../data/routes'

import NavbarElement, { NavbarElementProps } from './NavbarElement'

const useStyles = makeStyles((theme: Theme) => ({
  navbar: {
    height: 80,
    padding: `${theme.spacing(4)} 0`,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
    zIndex: 5,
  },
  logo: {
    width: 250,
    textAlign: 'center',
  },
}))

const navbarElements: Array<NavbarElementProps> = [
  { text: 'Brand', to: Routes.BRAND, icon: BrandIcon },
  { text: 'Design', to: Routes.DESIGN, icon: DesignIcon },
  { text: 'Components', to: Routes.COMPONENTS, icon: ComponentsIcon },
]

const Navbar: React.FC<React.PropsWithChildren<unknown>> = () => {
  const classes = useStyles()

  return (
    <Flex is="nav" className={classes.navbar} alignItems="center" fullWidth>
      <BaseLink to="/">
        <Logo type="horizontal" color="white" height="32px" className={classes.logo} />
      </BaseLink>
      <Flex horizontalSpacing="wide" marginLeft="hero">
        {navbarElements.map((navbar) => (
          <NavbarElement key={navbar.to} {...navbar} />
        ))}
      </Flex>
    </Flex>
  )
}

export default Navbar
