import { Theme } from '@eversports/react-components/design-tokens/theme'
import Card from '@eversports/react-components/primitives/Card'
import Divider from '@eversports/react-components/primitives/Divider'
import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Icon, { IconType } from '@eversports/react-components/primitives/Icon'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  card: ({ color }: { color: string }) => {
    const colorParts = color.split('.')
    let themeColor = color

    if (colorParts.length > 1) {
      // @ts-ignore No index type
      themeColor = theme.palette[colorParts[0]][colorParts[1]]
    }
    return {
      borderTop: `5px solid ${themeColor}`,
    }
  },
}))

interface Props {
  icon: IconType
  title?: string
  color: string
}

const InfoCard: React.FC<React.PropsWithChildren<Props>> = ({ children, icon, title, color }) => {
  const classes = useStyles({ color })

  return (
    <Card padding="wide" className={classes.card} raised={!!title}>
      <Flex flexDirection="column" verticalSpacing="default">
        {title && (
          <>
            <Flex horizontalSpacing="default">
              <Icon icon={icon} color={color} size="large" />
              <Heading is="h5" variant="small">
                {title}
              </Heading>
            </Flex>
            <Divider />
          </>
        )}
        <Flex horizontalSpacing="wide">
          {!title && <Icon icon={icon} color={color} size="large" />}
          <Heading is="h4" variant="small">
            {children}
          </Heading>
        </Flex>
      </Flex>
    </Card>
  )
}

export default InfoCard
