import Flex from '@eversports/react-components/primitives/Flex'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import BrandHeadingCover from '../../../assets/heading-cover-3.jpg'
import ClickableCard, {
  ClickableCardContent,
  ClickableCardHeading,
  ClickableCardLink,
  ClickableCardText,
} from '../../components/ClickableCard'
import Routes from '../../data/routes'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

const Brand: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex verticalSpacing="wide" flexDirection="column" alignItems="flex-start">
    <BrandedHeading img={BrandHeadingCover} alternativeColor>
      Brand Identity
    </BrandedHeading>
    <LimitedWidthSection>
      <Text>
        If you skip exercising for only a couple of days in a row the health benefits of exercising start to dissipate.
        It’s safe to say that consistency is the most important element in training, no matter at what level. So, it’s
        key to have a plan that keeps you motivated to come back and exercise regularly.
      </Text>
      <Text>
        Eversports keeps you moving. With the option to choose what you want to do whenever you want it’s easier to keep
        motivated and never skip a day of exercise. With Eversports you don’t need different memberships to do so, you
        can book classes on-demand using the app.
      </Text>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <ClickableCard to={Routes.BRAND_OUR_JOURNEY}>
          <ClickableCardContent>
            <ClickableCardHeading is="h3">Our Journey</ClickableCardHeading>
            <ClickableCardText>Find out more about the journey of Eversports over the last years.</ClickableCardText>
            <ClickableCardLink>Read about our Journey</ClickableCardLink>
          </ClickableCardContent>
        </ClickableCard>
        <ClickableCard to={Routes.BRAND_VALUES}>
          <ClickableCardContent>
            <ClickableCardHeading is="h3">Values</ClickableCardHeading>
            <ClickableCardText>
              Understand the values that we defend and that we use to push us ourselves forward as a company.
            </ClickableCardText>
            <ClickableCardLink>Read about our Values</ClickableCardLink>
          </ClickableCardContent>
        </ClickableCard>
        <ClickableCard to={Routes.BRAND_VISION}>
          <ClickableCardContent>
            <ClickableCardHeading is="h3">Vision</ClickableCardHeading>
            <ClickableCardText>
              See how our vision shapes the way we create our products and empower our people.
            </ClickableCardText>
            <ClickableCardLink>Read about our Vision</ClickableCardLink>
          </ClickableCardContent>
        </ClickableCard>
        <ClickableCard to={Routes.BRAND_MISSION}>
          <ClickableCardContent>
            <ClickableCardHeading is="h3">Mission</ClickableCardHeading>
            <ClickableCardText>See what motivates everyday to do our best to improve our processes.</ClickableCardText>
            <ClickableCardLink>Read about our Mission</ClickableCardLink>
          </ClickableCardContent>
        </ClickableCard>
      </Flex>
    </LimitedWidthSection>
  </Flex>
)

export default Brand
