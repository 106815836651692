import React from 'react'

import Routes from '../../data/routes'
import SidebarSection from '../SidebarSection'

const routesMapping = [
  { text: 'Our Journey', to: Routes.BRAND_OUR_JOURNEY },
  { text: 'Values', to: Routes.BRAND_VALUES },
  { text: 'Vision', to: Routes.BRAND_VISION },
  { text: 'Mission', to: Routes.BRAND_MISSION },
]

const BrandSidebarSection: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SidebarSection routesMapping={routesMapping} />
)

export default BrandSidebarSection
