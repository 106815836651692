import { Theme } from '@eversports/react-components/design-tokens/theme'
import Text from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(1),
    borderRadius: 50,
  },
}))

const Badge: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.badge}>
      <Text size="x-small" fontWeight="bold" color="white">
        {children}
      </Text>
    </div>
  )
}

export default Badge
