import Flex from '@eversports/react-components/primitives/Flex'
import { Icons } from '@eversports/react-components/primitives/Icon'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import BrandHeadingCover from '../../../assets/heading-cover-3.jpg'
import InfoCard from '../../components/InfoCard'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

const Vision: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex flexDirection="column" verticalSpacing="wide">
    <BrandedHeading img={BrandHeadingCover} alternativeColor>
      Vision
    </BrandedHeading>
    <LimitedWidthSection>
      <Text>
        We want to connect you, sports & yoga enthusiasts, trainers, teachers and relevant service providers via a
        central platform in order to enable everyone to do their favorite sports, wherever they are, whenever they want.
      </Text>
      <Text>
        Icing brownie lollipop ice cream pie soufflé icing. Oat cake ice cream liquorice macaroon. Carrot cake candy
        cotton candy bear claw carrot cake wafer sugar plum. Tootsie roll cupcake donut chupa chups muffin pastry
        jelly-o oat cake dessert. Danish chocolate bar chocolate tart sesame snaps dessert gingerbread pie muffin.
        Caramels pudding cake pastry macaroon. Jelly gummies cupcake icing tiramisu.
      </Text>
      <Text>
        Ice cream bear claw lollipop gummi bears carrot cake caramels lollipop. Jelly beans gingerbread tiramisu jelly
        candy canes. Sugar plum jelly beans gummi bears donut. Croissant sugar plum chocolate bar apple pie jelly.
      </Text>
      <Text>
        Toffee jujubes candy canes chupa chups sugar plum cookie icing. Gummi bears jelly beans danish icing croissant
        liquorice lemon drops. Muffin icing chocolate cake cookie topping sweet toffee jelly-o chocolate.
      </Text>
      <InfoCard title="Our Motto" color="primary.main" icon={Icons.ADD}>
        `&quot;`We want to empower all people to improve their lives with sport and yoga.`&quot;`
      </InfoCard>
    </LimitedWidthSection>
  </Flex>
)

export default Vision
