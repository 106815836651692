import React from 'react'

import Routes from '../../data/routes'
import SidebarSection from '../SidebarSection'

const routesMapping = [
  { text: 'Button', to: Routes.COMPONENTS_BUTTON },
  { text: 'Textfield', to: Routes.COMPONENTS_TEXTFIELD },
  { text: 'Avatar', to: Routes.COMPONENTS_AVATAR },
  { text: 'Processing', to: Routes.COMPONENTS_PROCESSING },
]

const ComponentsSidebarSection: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SidebarSection routesMapping={routesMapping} />
)

export default ComponentsSidebarSection
