import { Theme } from '@eversports/react-components/design-tokens/theme'
import Flex from '@eversports/react-components/primitives/Flex'
import Text from '@eversports/react-components/primitives/Text'
import ToolTip from '@eversports/react-components/primitives/ToolTip'
import copyToClipBoard from '@eversports/react-components/utilities/copyToClipBoard'
import { makeStyles } from '@mui/styles'
import chroma from 'chroma-js'
import React, { useState } from 'react'

export interface ColorItem {
  name: string
  color: string
  alternativeColor?: boolean
}

interface StyleProps {
  color: string
  alternativeColor?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  tile: ({ color }: StyleProps) => ({
    width: 168,
    height: 168,
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: color,
    cursor: 'pointer',
    borderRadius: theme.borderRadii.default,
    border: color === theme.palette.white ? theme.borders.default : theme.borders.none,
  }),
}))

const ColorTile: React.FC<React.PropsWithChildren<ColorItem>> = ({ name, color, alternativeColor }) => {
  const classes = useStyles({ alternativeColor, color })
  const [showToolTip, setShowToolTip] = useState(false)

  const handleCopy = () => {
    copyToClipBoard(color)
    setShowToolTip(true)
  }

  return (
    <ToolTip
      onClose={() => setShowToolTip(false)}
      open={showToolTip}
      disableFocusListener
      disableTouchListener
      title="Color Copied!"
    >
      <span>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className={classes.tile}
          onClick={handleCopy}
          verticalSpacing="dense"
        >
          <Text textAlign="center" color={alternativeColor ? 'white' : 'text.primary'}>
            {name}
          </Text>
          <Text textAlign="center" color={alternativeColor ? 'white' : 'text.primary'} size="small">
            {chroma(color).hex()}
          </Text>
        </Flex>
      </span>
    </ToolTip>
  )
}

export default ColorTile
