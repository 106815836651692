import Flex from '@eversports/react-components/primitives/Flex'
import { Icons } from '@eversports/react-components/primitives/Icon'
import Text from '@eversports/react-components/primitives/Text'
import UnorderedList from '@eversports/react-components/primitives/UnorderedList'
import React from 'react'

import BrandHeadingCover from '../../../assets/heading-cover-3.jpg'
import InfoCard from '../../components/InfoCard'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

const Values: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex flexDirection="column" verticalSpacing="wide">
    <BrandedHeading img={BrandHeadingCover} alternativeColor>
      Values
    </BrandedHeading>
    <LimitedWidthSection>
      <Text>
        Our vision is to simplify the access to sport facilities in your environment. We thrive to build a unique
        community of sporters and to share our passion around the globe. Eversports connects its members directly with
        sport venues, helps them to book courts or courses and to meet sport partners.{' '}
      </Text>
      <Text>
        With Eversports you know exactly where and when to practice your favourite sport. Find all the information you
        need in one single interface. Eversports is the marketplace where sport providers and sporters get easily
        connected.
      </Text>
      <Text>
        Macaroon icing sugar plum sweet jelly lemon drops marzipan. Powder cookie gummi bears toffee pastry icing
        chocolate cake jujubes lollipop. Ice cream donut sugar plum. Caramels tootsie roll muffin chocolate cake wafer
        pudding. Topping dragée chocolate ice cream tiramisu. Tart candy canes carrot cake toffee.
      </Text>
      <Text>
        Powder ice cream pastry tootsie roll gummi bears jujubes caramels wafer pie. Muffin halvah pie chupa chups cake
        powder candy canes ice cream. Soufflé jelly brownie chocolate bar pudding carrot cake biscuit.
      </Text>
      <InfoCard title="Our Values" color="primary.main" icon={Icons.INFO}>
        <UnorderedList bulletSize="x-small">
          <Text>Everytime, everywhere, eversports</Text>
          <Text>There’s no “I” in team - we win together</Text>
          <Text>We work best when we’re on the same field </Text>
          <Text>Scoring goals means solving problems with impact </Text>
          <Text>We turn our customers and users into fans </Text>
        </UnorderedList>
      </InfoCard>
    </LimitedWidthSection>
  </Flex>
)

export default Values
