import { Theme } from '@eversports/react-components/design-tokens/theme'
import Avatar from '@eversports/react-components/primitives/Avatar'
import Button from '@eversports/react-components/primitives/Button'
import Flex from '@eversports/react-components/primitives/Flex'
import Icon, { Icons } from '@eversports/react-components/primitives/Icon'
import Processing from '@eversports/react-components/primitives/Processing'
import TextField from '@eversports/react-components/primitives/TextField'
import { makeStyles } from '@mui/styles'
import vsDark from 'prism-react-renderer/themes/vsDark'
import PyroForm from 'pyro-form'
import React from 'react'
import { LiveEditor, LiveError, LivePreview, LiveProvider, LiveProviderProps } from 'react-live'

import AvatarImageExample from '../../assets/avatar-example.jpg'

const useStyles = makeStyles((theme: Theme) => ({
  livePreview: {
    padding: theme.spacing(4),
    border: `3px solid ${theme.palette.text.primary}`,
  },
}))

// Provide a global scope with all the primitives so we can use them in any example
const customScope = { Flex, Button, Icon, Icons, TextField, PyroForm, Avatar, AvatarImageExample, Processing }

const CustomLiveProvider: React.FC<React.PropsWithChildren<LiveProviderProps>> = (props) => (
  // @ts-ignore Need to better understand why the types fail here
  <LiveProvider {...props} scope={customScope} theme={vsDark} />
)

const CustomLivePreview: React.FC<React.PropsWithChildren<unknown>> = () => {
  const classes = useStyles()
  return <LivePreview className={classes.livePreview} />
}

export const CodeExample: React.FC<React.PropsWithChildren<Pick<LiveProviderProps, 'code'>>> = ({ code }) => (
  <CustomLiveProvider code={code}>
    <CustomLivePreview />
    <LiveEditor />
    <LiveError />
  </CustomLiveProvider>
)
