import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

const ClickableCardLink: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Text is="span" color="primary.dark" fontWeight="bold">
    → {children}
  </Text>
)

export default ClickableCardLink
