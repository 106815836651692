import Flex from '@eversports/react-components/primitives/Flex'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import DesignHeadingCover from '../../../assets/heading-cover-1.jpg'
import InfoBanner from '../../components/InfoBanner'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

import LogoTile from './views/LogoTile'

const Logo: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex flexDirection="column" alignItems="flex-start" verticalSpacing="default">
    <BrandedHeading img={DesignHeadingCover} alternativeColor>
      Logo
    </BrandedHeading>
    <LimitedWidthSection>
      <Text>This section will present you insights on how to use our Logo and which versions exist.</Text>
      <InfoBanner>For downloading the logo please go to assets section</InfoBanner>
      <Text>
        There are three different logo variations to fit all applications and needs. If possible try to use either the
        horizontal or vertical one since they are more explanatory also carrying the name.
      </Text>
      <Flex justifyContent="center" horizontalSpacing="hero" fullWidth marginTop="wide" marginBottom="wide">
        <LogoTile type="icon" />
        <LogoTile type="horizontal" />
        <LogoTile type="vertical" />
      </Flex>
      <Text>
        If necessary the logo can also be used colorless (black & white). This should only be used if necessary. As a
        default use one of the colorful logos above.
      </Text>
      <Flex justifyContent="center" horizontalSpacing="hero" fullWidth marginTop="wide">
        <LogoTile type="icon" color="black" />
        <LogoTile type="horizontal" color="black" />
        <LogoTile type="vertical" color="black" />
      </Flex>
      <Flex justifyContent="center" horizontalSpacing="hero" fullWidth marginTop="wide">
        <LogoTile type="icon" color="white" />
        <LogoTile type="horizontal" color="white" />
        <LogoTile type="vertical" color="white" />
      </Flex>
    </LimitedWidthSection>
  </Flex>
)

export default Logo
