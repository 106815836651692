import {
  Intl as IntlComponents,
  loadLanguage as loadLanguageComponents,
  Provider as LocalizationProviderComponents,
} from '@eversports/react-components/localization/react'
import React from 'react'

import {
  Intl as IntlDesignSystem,
  loadLanguage as loadLanguageDesignSystem,
  Locales,
  Provider as LocalizationProviderDesignSystem,
} from './localization/react'

interface Props {
  intlDesignSystem: IntlDesignSystem
  intlComponents: IntlComponents
}

const DesignSystemLocalizationProvider: React.FC<React.PropsWithChildren<Props>> = ({
  intlDesignSystem,
  intlComponents,
  children,
}) => (
  <LocalizationProviderDesignSystem value={intlDesignSystem}>
    <LocalizationProviderComponents value={intlComponents}>{children}</LocalizationProviderComponents>
  </LocalizationProviderDesignSystem>
)

async function getLocalizationProvider(locale: Locales): Promise<React.FC<React.PropsWithChildren<unknown>>> {
  const [intlDesignSystem, intlComponents] = await Promise.all([
    loadLanguageDesignSystem(locale),
    loadLanguageComponents(locale),
  ])

  // eslint-disable-next-line react/display-name
  return (props) => (
    <DesignSystemLocalizationProvider intlDesignSystem={intlDesignSystem} intlComponents={intlComponents} {...props} />
  )
}

export default getLocalizationProvider
