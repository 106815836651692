import { Theme } from '@eversports/react-components/design-tokens/theme'
import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Link from '@eversports/react-components/primitives/Link'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

export interface NavbarElementProps {
  text: string
  to: string
  icon: string
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 20,
  },
  navbarElement: ({ selected }: { selected: boolean }) => ({
    padding: theme.spacing(3),
    borderBottom: `6px solid ${selected ? theme.palette.white : theme.palette.transparent}`,
    opacity: selected ? 1 : 0.6,
    '&:hover': {
      opacity: 1,
    },
  }),
}))

const NavbarElement: React.FC<React.PropsWithChildren<NavbarElementProps & RouteComponentProps>> = ({
  to,
  icon,
  text,
  location,
}) => {
  const selected = `/${location.pathname.split('/')[1]}` === to
  const classes = useStyles({ selected: Boolean(selected) })

  return (
    <Link to={to}>
      <Flex horizontalSpacing="dense" className={classes.navbarElement}>
        <img src={icon} className={classes.icon} />
        <Heading is="h5" variant="small" alternativeColor>
          {text}
        </Heading>
      </Flex>
    </Link>
  )
}

export default withRouter(NavbarElement)
