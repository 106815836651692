import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import ComponentsHeadingCover from '../../../assets/heading-cover-2.jpg'
import { CodeExample } from '../../utils/CustomReactLiveComponents'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

const code = `
<Processing isLoading={true}>
  <Flex justifyContent="center">
    <Button variant="primary">Loading</Button>
  </Flex>
</Processing>
`

const ProcessingComponent: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex flexDirection="column" verticalSpacing="wide">
    <BrandedHeading img={ComponentsHeadingCover} alternativeColor />
    <LimitedWidthSection>
      <Flex horizontalSpacing="dense">
        <Heading is="h4">Processing</Heading>
      </Flex>
      <Text>
        Use in conjunction with stateful components (p.ex apollo queries or mutations) to visually show the user that
        some operations is currently being processed in the background.
      </Text>
      <CodeExample code={code} />
    </LimitedWidthSection>
  </Flex>
)

export default ProcessingComponent
