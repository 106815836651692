import { Theme } from '@eversports/react-components/design-tokens/theme'
import Flex from '@eversports/react-components/primitives/Flex'
import { makeStyles } from '@mui/styles'
import React from 'react'

import Content from './sections/Content'
import Navbar from './sections/Navbar'
import Sidebar from './sections/Sidebar'

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    height: '100vh',
    overflowY: 'hidden',
  },
  sections: {
    height: '100%',
  },
  leftSection: {
    width: 250,
    backgroundColor: theme.palette.text.primary,
    boxShadow: theme.shadows[2],
    zIndex: 2,
  },
  rightSection: {
    backgroundColor: theme.palette.white,
    overflowY: 'scroll',
  },
}))

const Layout: React.FC<React.PropsWithChildren<unknown>> = () => {
  const classes = useStyles()

  return (
    <Flex flexDirection="column" className={classes.main} fullWidth>
      <Navbar />
      <Flex className={classes.sections}>
        <Flex
          is="aside"
          className={classes.leftSection}
          flexDirection="column"
          verticalSpacing="default"
          flexShrink={0}
        >
          <Sidebar />
        </Flex>
        <Flex className={classes.rightSection} flexDirection="column" verticalSpacing="default" fullWidth>
          <Content />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Layout
