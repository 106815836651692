import Flex from '@eversports/react-components/primitives/Flex'
import React from 'react'

import SidebarSectionElement from './SidebarSectionElement'

export interface RouteMapping {
  text: string
  to: string
  disabled?: boolean
}

interface Props {
  routesMapping: Array<RouteMapping>
}

const SidebarSection: React.FC<React.PropsWithChildren<Props>> = ({ routesMapping }) => (
  <Flex flexDirection="column" verticalSpacing="dense" marginTop="hero" alignItems="center">
    {routesMapping.map((route) => (
      <SidebarSectionElement key={route.to} route={route} />
    ))}
  </Flex>
)

export default SidebarSection
