import Flex from '@eversports/react-components/primitives/Flex'
import { Icons as allIcons } from '@eversports/react-components/primitives/Icon'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import DesignHeadingCover from '../../../assets/heading-cover-1.jpg'
import InfoBanner from '../../components/InfoBanner'
import BrandedHeading from '../BrandedHeading'
import LimitedWidthSection from '../LimitedWidthSection'

import IconTile, { AllIcons } from './views/IconTile'

const Icons: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex flexDirection="column" verticalSpacing="default" alignItems="flex-start" fullWidth>
    <BrandedHeading img={DesignHeadingCover} alternativeColor>
      Icons
    </BrandedHeading>
    <LimitedWidthSection>
      <Text>
        Here you can take a find all icons which are available in Eversports applications. Our icons are in SVG format,
        in order to offer our users the best image quality.
      </Text>
      <InfoBanner>For downloading the logo please go to assets section</InfoBanner>
      <Flex horizontalSpacing="default" verticalSpacing="default" flexWrap="wrap">
        {Object.keys(allIcons).map((icon) => (
          <IconTile key={icon} icon={icon as AllIcons} />
        ))}
      </Flex>
    </LimitedWidthSection>
  </Flex>
)

export default Icons
