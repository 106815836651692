import { default as MuiTooltip, TooltipProps } from '@mui/material/Tooltip'
import * as React from 'react'

// The default element type of MUI's ToolTip is 'div' and the change of this prop is not allowed
type ToolTipRef = HTMLElementTagNameMap['div']

// eslint-disable-next-line no-shadow
export const ToolTip = React.forwardRef<ToolTipRef, TooltipProps>(function ToolTip(props, ref) {
  return <MuiTooltip {...props} ref={ref} />
})

export default ToolTip
