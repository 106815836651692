import { Theme } from '@eversports/react-components/design-tokens/theme'
import Flex from '@eversports/react-components/primitives/Flex'
import LogoComponent, { LogoProps } from '@eversports/react-components/primitives/Logo'
import Text from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadii: theme.borderRadii.default,
  },
  logoContainer: {
    padding: theme.spacing(1),
  },
  whiteLogoContainer: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
}))

interface Props {
  type: NonNullable<LogoProps['type']>
  color?: LogoProps['color']
}

const LogoTypeMap = {
  icon: 'Icon Only',
  horizontal: 'Horizontal Logo',
  vertical: 'Vertical Logo',
}

const LogoTile: React.FC<React.PropsWithChildren<Props>> = ({ type, color }) => {
  const classes = useStyles()
  return (
    <Flex flexDirection="column" alignItems="center" verticalSpacing="default" className={classes.container}>
      <div className={color === 'white' ? classes.whiteLogoContainer : classes.logoContainer}>
        <LogoComponent type={type} height="64px" color={color} />
      </div>
      <Text is="span" size="small" color="subtleText">
        {LogoTypeMap[type]}
      </Text>
    </Flex>
  )
}

export default LogoTile
